<template>
  <NavBar/>
  <div class="body">
    <h1>
      Family Page
    </h1>
  </div>
</template>
  
  <script>
  import NavBar from '../components/NavBar.vue'

  export default {
    name: 'FamilyPortal',
    components: {
    NavBar
}

  }
  
  </script>
  
  <style>
  .body {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #439465;
  }
  
  </style>