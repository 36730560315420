<template>
    <NavBar/>
    <div class="OlliesPage">
        <span class="OlliesPage__FlashCard">
            <FileUpload/>
        </span>
    </div>
</template>

<script>
import NavBar from '../components/NavBar.vue'
import FileUpload from '../components/FileUpload.vue'

export default {
    name: 'OliversPage',
    components: {
    NavBar,
    FileUpload
}
}
</script>

<style scoped>
    .OlliesPage {
        height: 100vh;
        width: 100vw;
        border: 1px solid black;
        background-image: url('../assets/Images/onepiece.jpg');
        background-size: cover;
        background-position: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }

</style>