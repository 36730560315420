<template>
  <div class="FlashCardPage">
    <div class="FlashCardPage__Card">
      <FlashCard 
      :flashcards=this.flashcards
      :randomflashcards="randomflashcards"
      > </FlashCard>
    </div>


  </div>
</template>

<script>
/* eslint-disable */
import Papa from 'papaparse'
import FlashCard from './FlashCard.vue'

export default {
  name: 'FileUpload',
  data: function() {
    return {
      flashcards: [],
      randomflashcards: []
    }
  },
  created(){
    fetch('/Data/lesson11.csv')
      .then(response => response.text())
      .then(csvData => {
        console.log(csvData);
        Papa.parse(csvData, {
        header: true,
        delimiter: ",",
        dynamicTyping: true,
        skipEmptyLines: true,
        complete: (results) => {
          if(!results.data.length) 
            return;
          else{
            this.flashcards = [...results.data].sort(() => Math.random() - 0.5);
            this.randomflashcards = [...results.data].sort(() => Math.random() - 0.5);
          
          }
        }
      });
    })
  },
  components: {
    FlashCard
  },

}
</script>

<style scoped>
.FlashCardPage {
  background-color: rgba(247, 226, 226, 0.638);
    border: 1px solid black;
    border-radius: 10px;
    height: 50vh;
    width: 50vw;
    margin: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .FlashCardPage__Card{
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
    }

  }

</style>