<template>
    <nav class="navbar">
        <RouterLink class="navbar-item" to="/">Family Portal</RouterLink>
        <RouterLink class="navbar-item" to="/evas-page">Evas Page</RouterLink>
        <RouterLink class="navbar-item" to="/olivers-page">Olivers Page</RouterLink>
    </nav>
</template>

<script>
export default {
    name: 'NavBar'
}
</script>

<style scoped>
.navbar {
    background-color: #f8f9fa;
    display: flex;
    justify-content: space-around;
    border-bottom: 2px solid #ebebeb;
}

</style>