<template>
    <div class="question" v-if="currentFlashCard">
        <h1>{{ currentFlashCard.question }}</h1>
    </div>
    <div class="Mid">
        <div class="answerSection">
            <div class="answerBox">
                <input type="text" placeholder="Type your answer here!" class="answerBox" v-model="flashcard.answer" @keyup.enter="checkAnswer" />
            </div>
        </div>
        <div :class ="{'greenLightBox': correctAnswer ,'redLightBox': !correctAnswer }">
            <div v-if="currentFlashCard" class="answer">
                <h1 class="answer" v-if="showAnswer">{{ currentFlashCard.answer }}</h1>
            </div>
        </div>
    </div>
    <div v-if="showPossible" class="possible">
        <li v-for="(randomflashcard , index) in randomflashcards" :key="index">
        {{ randomflashcard.answer }}
        </li>
    </div>
    <button @click="showPossible = !showPossible">
        {{ showPossible ? 'Hide Vocab Words' : 'Show Vocab Words' }}
    </button>
    <div class="navigationSection">
        <button class="PreviousCard" @click="previousFlashCard" :disabled="flashcardIndex === 0">Previous</button>
        <button class="FlipCard" @click="flipFlashCard">Reset</button>
        <button class="NextCard" @click="nextFlashCard" :disabled="flashcardIndex === flashcards.length - 1" >Next</button>
    </div>
</template>

<script>
/* eslint-disable */
export default {
    name: 'FlashCard',
    props: {
        flashcards: {
            type: Array,
            required: true
        },
        randomflashcards: {
            type: Array,
            required: true
        }
    },
    data: function() {
        return {
            flashcard: {
                question: '',
                answer: ''
            },
            randomflashcard: {
                question: '',
                answer: ''
            },
            flashcardIndex: 0,
            showAnswer: false,
            correctAnswer: false, 
            showPossible: false   
        }
    },
    mounted() {
        this.correctAnswer = false;
    },
    methods: {
        nextFlashCard: function() {
            this.clearData()
            this.flashcardIndex++;
            this.showAnswer = false;
            this.correctAnswer = false;
        },
        previousFlashCard: function() {
            this.clearData()
            this.flashcardIndex--;
            this.showAnswer = false;
            this.correctAnswer = false;
        },
        flipFlashCard: function() {
            this.showAnswer = false;
            this.clearData();
        },
        clearData: function() {
            this.flashcard.answer = ""
        },
        checkAnswer: function() {
            if (this.flashcard.answer.toLocaleLowerCase() === this.currentFlashCard.answer.toLocaleLowerCase()) {
                this.correctAnswer = true;
            } else {
                this.correctAnswer = false;
            }
            this.showAnswer = true;
        },
    },
    computed: {
        currentFlashCard: function() {
            console.log(this.flashcardIndex)
            return this.flashcards[this.flashcardIndex];
        },

    }
}

</script>

<style>
    .navigationSection{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 10px;
        padding: 10px;
        border: 1px solid black;
        border-radius: 25px;

    .PreviousCard{
        padding: 10px 20px;
        background-color: #303030; /* Change as needed */
        color: rgba(255, 255, 255); /* Change as needed */
        text-align: center;
        cursor: pointer;
        border-radius: 25px;
        font-size: 25px;
        transition: background-color 0.3s ease;
    }
    .PreviousCard:hover{
        background-color: #0ac500; 
    }
        .FlipCard{
            padding: 10px 20px;
            background-color: #303030; /* Change as needed */
            color: rgba(255, 255, 255); /* Change as needed */
            text-align: center;
            cursor: pointer;
            border-radius: 25px;
            font-size: 25px;
            transition: background-color 0.3s ease;
        }
        .FlipCard:hover{
            background-color: #0ac500; 
        }
        .NextCard{
            padding: 10px 20px;
            background-color: #303030; /* Change as needed */
            color: rgba(255, 255, 255); /* Change as needed */
            text-align: center;
            cursor: pointer;
            border-radius: 25px;
            font-size: 25px;
            transition: background-color 0.3s ease;
        }
        .NextCard:hover{
            background-color: #0ac500; 
        }
    }
    .question{
        border: 1px black solid;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 33%;
        width: 100%;
    }

    .Mid{
        height: 33%;
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;

     .answerSection{
        height: 50%;
        width: 50%;
        display: flex;
        justify-content: space-around;
        align-items: center;
    }
    .greenLightBox{
        height: 50%;
        width: 50%;
        border: black 1px solid;
        border-radius: 50px;
        background-color: green;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .redLightBox{
        height: 50%;
        width: 50%;
        border: black 1px solid;
        border-radius: 50px;
        background-color: red;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .answerBox {
    width: 80%;
    padding: 10px 20px;
    margin: 4px 0;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 25px;
    background-color: #fcfcfc8c;
    font-size: 16px;
    transition: 0.3s;
    font-size: 20px;
}

.answerBox:focus {
    border-color: #4CAF50;
    background-color: #e9f6e9;
}
}
    .possible{
        display: flex;
        list-style-type: none;
        justify-content: space-between;
        font-size: small;
        font-weight: bold;
        display: flex;
        background: rgb(9, 32, 53);
        border-radius: 10px;
        padding: 1em;
        flex-wrap: wrap;

        li{
            padding: 1em;
            margin: 1em;
            border: 1px solid black;
            background-color: #ccc;
            border-radius: 10px;
        }
}



</style>