import { createRouter, createWebHistory } from 'vue-router'
import FamilyPortal from './views/FamilyPortal.vue'
import EvasPage from './views/EvasPage.vue'
import OliversPage from './views/OliversPage.vue'

const routes = [
  { path: '/', component: FamilyPortal },
  { path: '/Evas-Page', component: EvasPage },
  { path: '/Olivers-Page', component: OliversPage }
]

const router = createRouter({
    history: createWebHistory(),
    routes
  })

  


export default router