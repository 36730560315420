<template>
    <NavBar/>
    <div class="evas-page">
        <h1> Eva's Page </h1>
    </div>
</template>

<script>
import NavBar from '../components/NavBar.vue'
export default {
    name: 'EvasPage',
    components: {
    NavBar
}
}
</script>

<style scoped>
.evas-page {
    background-color: #ffc0cb; /* pink background */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; 
}
</style>